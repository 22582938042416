#Filter {

    input,
    .ant-select,
    .ant-select-selector,
    .ant-select-item-option-content {
        font-size: 12px !important;
    }

    label {
        font-weight: 500;
        cursor: text;
    }
}